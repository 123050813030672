import {
  lens85mm,
  actionCamera,
  actionCamera2,
  actionCamera3,
  cameraCanon,
  cameraCanon2,
  cameraCanon3,
  cameraCanon4,
  cameraCanon5,
  canon6dCamera,
  canon6dCamera2,
  canon6dCamera3,
  canon6dCamera4,
  canon6dCamera5,
  lensForMobile,
  lensForMobile2,
  lensForMobile3,
  lensForMobile4,
  lensForMobile5,
  polaroidCamera,
  polaroidCamera2,
  polaroidCamera3,
  polaroidCamera4,
  polaroidCamera5,
  sonyAlphaCamera,
  sonyAlphaCamera2,
  sonyAlphaCamera3,
  sonyAlphaCamera4,
  sonyAlphaCamera5,
  sonySmallCamera,
  sonySmallCamera2,
  sonySmallCamera3,
  sonySmallCamera4,
  cameraStrap,
  cameraStrap2,
  cameraStrap3,
  cameraStrap4,
  cameraStrap5,
  digitekTripod,
  digitekTripod2,
  digitekTripod3,
  digitekTripod4,
  digitekTripod5,
  godoxFlash,
  godoxFlash2,
  godoxFlash3,
  godoxFlash4,
  godoxFlash5,
  humbleTripod,
  humbleTripod2,
  humbleTripod3,
  humbleTripod4,
  humbleTripod5,
  msgTripod,
  msgTripod2,
  msgTripod3,
  msgTripod4,
  msgTripod5,
  polaroidBag,
  polaroidBag2,
  polaroidBag3,
  polaroidBag4,
  polaroidBag5,
  polaroidFilm,
  polaroidFilm2,
  polaroidFilm3,
  polaroidFilm4,
  polaroidFilm5,
  sandisk128,
  sandisk1282,
  sandisk1283,
  sandisk1284,
  sandisk1285,
  sandisk256,
  sandisk2562,
  sandisk2563,
  sandisk2564,
  sandisk2565,
} from "Data/Img/Products/ProductImages";

/*
  Product Database 
*/

const products = [
  {
    _id: "2022PEBCART2705PEBUI01",
    category: "camera",
    brand: "sony",
    title: "Sony DSC W830 Cyber-Shot 20.1 MP Point & Shoot Camera",
    price: 10999,
    rating: 4,
    totalRating: 13,
    src1: sonySmallCamera,
    src2: sonySmallCamera2,
    src3: sonySmallCamera3,
    src4: sonySmallCamera4,
    src5: sonySmallCamera,
    inStock: 20,
    newestArrival: true,
    delivery: `${Math.trunc(Math.random() * 10) + 2} Days`,
    description: [
      "Super HAD CCD sensor with 20.1 effective megapixels : Included Components - 1 U (Including-Rechargeable battery, AC Adaptor, Multi USB Cable, Wrist Strap,User Manual)",
      "720p MP4 movie mode the camera shoots 1280 x 720 high definition movies at 30 fps, Focal length f=4.5-36mm. Exposure Compensation: +/- 2.0 EV, 1/3 EV step",
      "8x optical zoom Carl Zeiss Vario Tessar lens.Refer user manual ; Screen type: 6.7cm(2.7-type)(4:3) / 230,400 dots / ClearPhoto / TFT LCD ; Brightness Control: 5 (Bright) / 4 / 3 / 2 / 1 (Dark)",
      "Equipped with sweep panorama, intelligent auto and picture effect, Self-Timer: Off , 10sec., 2sec., portrait1, portrait2",
      "Power Consumption (Camera Mode): Approx. 1.2W; Power Source: DC3.6V (supplied battery)/DC5.0V (supplied AC adaptor)",
    ],
  },
  {
    _id: "2022PEBCART2705PEBUI02",
    category: "camera",
    brand: "canon",
    title: "Canon EOS 6D DSLR Camera (Kit 24 - 105)  (Black)",
    price: 135100,
    rating: 4.5,
    totalRating: 40,
    src1: canon6dCamera,
    src2: canon6dCamera2,
    src3: canon6dCamera3,
    src4: canon6dCamera4,
    src5: canon6dCamera5,
    inStock: 13,
    newestArrival: false,
    delivery: `${Math.trunc(Math.random() * 10) + 2} Days`,
    description: [
      "This camera comes with a high resolution 20.2-megapixel CMOS sensor capture sharp images with quality and detail. It has a new photodiode construction, gapless microlens array and on-chip noise reduction circuitry on the sensor to keep the image data clean and clear, even at high ISO settings.",
      "The Canon EOS 6D lets you take pictures in any environment, bright or low-lit, as it comes with a flexible ISO speed setting between 50 and 102400. It lets you adjust the camera's sensitivity to light, giving you great photos in both day and night.",
      "As the Canon EOS 6D supports IEEE 802.11 b/g/n networks, you share data between your camera and Wi-Fi enabled devices or on social networking sites. With built-in GPS tracking, you can tag the place where you took the photo with accurate GPS coordinates. You can also use your smartphone to remotely control the camera through Wi-Fi.",
      "Record high definition videos with manual exposure control and multiple frame rates. Record 1080p videos at 30 frames per second or 720p videos at 60 frames per second.",
      "High Dynamic Range lets you take high-quality videos with ease, allowing you to expand your creative mindset using various features such as Scene Intelligent Auto and Special Scene Modes to get the perfect shot.",
      "This camera is compatible with SD/SDHC/SDXC memory cards, allowing you to store more photos and videos.",
    ],
  },
  {
    _id: "2022PEBCART2705PEBUI32",
    category: "lens",
    brand: "canon",
    title: "Canon EF 85mm f1.2L II USM Lens for Canon DSLR Cameras",
    price: 85100,
    rating: 4.2,
    totalRating: 13,
    src1: lens85mm,
    src2: lens85mm,
    src3: lens85mm,
    src4: lens85mm,
    src5: lens85mm,
    inStock: 3,
    newestArrival: false,
    delivery: `${Math.trunc(Math.random() * 10) + 2} Days`,
    description: [
      "Beautiful bokeh with DS* coating",
      "High image quality and bright f/1.2 aperture mid-telephoto zoom RF L lens.",
      "Blue spectrum refractive optics (br) Reduces chromatic aberration.",
      "Minimum focusing distance of 2.79 ft./0.85M.",
      "Control ring for direct setting changes.",
      "Lens Type: Macro",
    ],
  },
  {
    _id: "2022PEBCART2705PEBUI03",
    category: "lens",
    brand: "apexel",
    title: "Apexel 4K HD Mobile Phone 5-in-1 Camera Lens Kit",
    price: 350,
    rating: 1,
    totalRating: 25,
    src1: lensForMobile,
    src2: lensForMobile2,
    src3: lensForMobile3,
    src4: lensForMobile4,
    src5: lensForMobile5,
    inStock: 0,
    newestArrival: false,
    delivery: `${Math.trunc(Math.random() * 10) + 2} Days`,
    description: [
      "The 4K HD Mobile Phone 5-in-1 Camera Lens Kit from Apexel is a bundle that contains a 2x Zoom Telephone Lens, a 0.6x / 110° Wide-Angle Lens, a 170° Super Wide-Angle Lens, a 195° Fisheye Lens, and a 10x Macro Lens. These lenses are compatible with iPhone XS Max, XR, X, 8, 7, and 6 Plus, as well as Samsung phones.",
      "This lens provides a magnification of 10x for detailed close-ups. The Telephoto Lens provides a minimum magnification of 2x to deliver photos from a distance that are of higher quality. If you'd like to create subjects with a blurred background, please keep the lens 12 to 40 from your subject. To fully utilize the 2x magnification, keep the lens at least 60 from the subject.",
      "Shoot a wide range of scenery with a magnification of 0.6x and a 110° angle of view. This lens is suitable for group selfies, travel, landscapes, and more.",
    ],
  },
  {
    _id: "2022PEBCART2705PEBUI04",
    category: "camera",
    brand: "polaroid",
    title: "Polaroid Originals Now I-Type Instant Camera - (9030)",
    price: 12999,
    rating: 1.8,
    totalRating: 14,
    src1: polaroidCamera,
    src2: polaroidCamera2,
    src3: polaroidCamera3,
    src4: polaroidCamera4,
    src5: polaroidCamera5,
    inStock: 4,
    newestArrival: false,
    delivery: `${Math.trunc(Math.random() * 10) + 2} Days`,
    description: [
      "NOW: Polaroid's new point-and-shoot analog instant camera has all you need to catch every life moment in an original Polaroid photograph.",
      "NEW & IMPROVED: Now with autofocus, it’s simple to capture moments as you see them, so you can relive them forever in sharp, vivid color.",
      " DOUBLE EXPOSURE: Frame two moments in one with double exposure, or get yourself in the picture with self-timer and an accurate flash to make everyone look like they should.",
      "COLORFUL FUN: Now is available in the 7 colors of the iconic Polaroid spectrum.",
      " POINT. SHOOT. KEEP FOREVER: Now is compatible with both i-Type and 600 film.",
    ],
  },
  {
    _id: "2022PEBCART2705PEBUI05",
    category: "camera",
    brand: "sony",
    title: "Sony Alpha ILCE-7RM3A Full-Frame 42.4MP Mirrorless Camera Body",
    price: 202900,
    rating: 2.8,
    totalRating: 34,
    src1: sonyAlphaCamera,
    src2: sonyAlphaCamera2,
    src3: sonyAlphaCamera3,
    src4: sonyAlphaCamera4,
    src5: sonyAlphaCamera5,
    inStock: 12,
    newestArrival: false,
    delivery: `${Math.trunc(Math.random() * 10) + 2} Days`,
    description: [
      "35mm full-frame “Exmor R” CMOS sensor with approx 42.4 effective megapixels",
      "Enhanced BIONZ X image processing engine with front end LSI",
      "ISO sensitivity: 100 – 32000 (expandable 50 – 102400)",
      "14bit RAW format (ARW)",
      "Image sensor-shift mechanism (5-axis compensation) of a 5.5 stop compensation performance",
      "No optical low-pass filter",
      "Pixel Shift Multi Shooting",
      "Continuous shooting at up to 10 fps (with AF/AE tracking)",
      "Silent and vibration-free shooting (Electronical shutter)",
      "2 Year standard warranty + 1 year extended warranty on registration on alpha community",
      "399 points Hybrid Phase Detection AF + 425 Contrast AF",
      "Country of Origin: China",
    ],
  },
  {
    _id: "2022PEBCART2705PEBUI06",
    category: "camera",
    brand: "canon",
    title: "Canon EOS 1500D 24.1 Digital SLR Camera EF S18-55 is II Lens",
    price: 18999,
    rating: 3.5,
    totalRating: 30,
    src1: cameraCanon,
    src2: cameraCanon2,
    src3: cameraCanon3,
    src4: cameraCanon4,
    src5: cameraCanon5,
    inStock: 0,
    newestArrival: false,
    delivery: `${Math.trunc(Math.random() * 10) + 2} Days`,
    description: [
      "Sensor: APS-C CMOS Sensor with 24.1 MP (high resolution for large prints and image cropping). Transmission frequency (central frequency):Frequency: 2 412 to 2 462MHz. Standard diopter :-2.5 - +0.5m-1 (dpt)",
      "ISO: 100-6400 sensitivity range (critical for obtaining grain-free pictures, especially in low light)",
      "Image Processor: DIGIC 4+ with 9 autofocus points (important for speed and accuracy of autofocus and burst photography)",
      "Video Resolution: Full HD video with fully manual control and selectable frame rates (great for precision and high-quality video work)",
      " Connectivity: WiFi, NFC and Bluetooth built-in (useful for remotely controlling your camera and transferring pictures wirelessly as you shoot)",
      "Lens Mount: EF-S mount compatible with all EF and EF-S lenses (crop-sensor mount versatile and compact, especially when used with EF-S lenses)",
      "Country of Origin: Taiwan",
    ],
  },
  {
    _id: "2022PEBCART2705PEBUI07",
    category: "camera",
    brand: "gopro",
    title: "GoPro HERO10 Black - Front LCD and Touch Rear Screens,23MP.",
    price: 35400,
    rating: 4.5,
    totalRating: 103,
    src1: actionCamera,
    src2: actionCamera2,
    src3: actionCamera3,
    src4: actionCamera,
    src5: actionCamera2,
    inStock: 10,
    newestArrival: true,
    delivery: `${Math.trunc(Math.random() * 10) + 2} Days`,
    description: [
      "1 year international + 1 year local India warranty. For 1 Year extended warranty please visit gopro.luxurypersonified.co.in",
      "Revolutionary Processor: Faster. Smoother. Better. The powerful new GP2 engine changes the game—snappy performance, responsive touch controls and double the frame rate for amazingly smooth footage. Designed specifically for the demanding nature of the GoPro, the GP2 “system on a chip” is by far our fastest ever.",
      "High-Res Photos + Videos, High Frame Rate: Step up to the sharpest-shooting GoPro ever. Upgraded to incredible 23MP photos and 5.3K video resolution at 60fps, HERO10 offers double the frame rate for amazingly smooth motion. Plus, there’s 8x slo-mo at 2.7K and you can pause videos and grab 15.8MP still photos from 5.3K video that look amazing.",
      "Unbelievable Image Quality: Shoot with fine detail, realistic textures and stunning contrast, even in low light. Images look their best thanks to a new water-shedding hydrophobic lens cover that repels water while helping to eliminate lens flare and other artifacts.",
      "HyperSmooth 4.0: HyperSmooth has never been smoother, and it’s easy to dial in the best stabilization at all times. Plus, get even better low-light performance and horizon leveling with a higher tilt limit, so your footage looks perfectly straight when you want it.",
      "Compatible with Over 30 Accessories",
    ],
  },
  {
    _id: "2022PEBCART2705PEBUI08",
    category: "tripod",
    brand: "digitek",
    title: "DIGITEK® (DTR 260 GT) Gorilla Tripod/Mini 33 cm (13 Inch) Tripod .",
    price: 399,
    rating: 4.1,
    totalRating: 1103,
    src1: digitekTripod,
    src2: digitekTripod2,
    src3: digitekTripod3,
    src4: digitekTripod4,
    src5: digitekTripod5,
    inStock: 10,
    newestArrival: true,
    delivery: `${Math.trunc(Math.random() * 10) + 2} Days`,
    description: [
      "Heavy Duty: The Gorillapod mobile tripod is made of high quality ABS. Making it a very heavy duty product and giving it an exponentially longer life, as compared to other plastic based camera tripod. Ensuring that even with a very rough usage your mobile phone, camera, phone, DSLR and tripod stand are safe. If Shooting in rough conditions and environments you have a peace of mind that everything will be safe. Maximum load: 1kg",
      "Rotating Sphere: An Anodised finishing rotating sphere in tripods for mobile or gorilla tripod ensures that you get a great load bearing capacity for your dslr camera, mobile tripod, DSLR tripod, gopro tripod. Along with an accurate angle lock. Which ensures that your phone stands can take photos at angles that you desire and will stay in that angle as long as needed. Which also ensures that no matter how heavy your device, the tripods is comfortable and can be used for a long duration.",
      "Portable & Lightweight: A portable design ensures that you can carry and use your mini tripods for mobile effortlessly throughout the day. ABS makes the mini tripod exceptionally lightweight and durable. It is perfect for a long day of gorillapod tripod shooting. Being a camera stand tripod you can also view your mobile phone or DSLR camera recorded videos in rough and unstable environments. With a peace of mind that your tripods for mobile, dslr camera and mobile phone will be safe.",
      "Mobile Attachment and Bluetooth Remote for DSLR, Action Cameras & Smartphones.",
    ],
  },
  {
    _id: "2022PEBCART2705PEBUI09",
    category: "accessories",
    brand: "polaroid",
    title: "Polaroid Instant Color Film for 600 cameras and i-Type cameras",
    price: 4050,
    rating: 4.5,
    totalRating: 313,
    src1: polaroidFilm,
    src2: polaroidFilm2,
    src3: polaroidFilm3,
    src4: polaroidFilm4,
    src5: polaroidFilm5,
    inStock: 2,
    newestArrival: false,
    delivery: `${Math.trunc(Math.random() * 10) + 2} Days`,
    description: [
      "CLASSIC FILM: Polaroid Instant Color Film for 600 cameras and i-Type cameras with 8 classic iconic white framed photos.",
      "LIGHT IT UP: Polaroid Instant Film loves light. The more light in your shot, the better your photo will turn out. Always shoot in bright light or use the camera flash.",
      "DEVELOP: All photos appear blank at first. Photos develop within 15 minutes. Shield photos from the light and place them face down as they develop.",
      "Equipped with sweep panorama, intelligent auto and picture effect, Self-Timer: Off , 10sec., 2sec., portrait1, portrait2",
      "CREATE: Every photo you create is rich textured and unique. Unpredictable, imperfect, and impossible to reproduce.",
      "BACKWARD COMPATIBLE: Film is compatible with Polaroid 600, Polaroid Impulse, Polaroid SLR680, and newer Polaroid i-Type One Step 2 cameras.",
    ],
  },
  {
    _id: "2022PEBCART2705PEBUI010",
    category: "accessories",
    brand: "sandisk",
    title: "SanDisk Extreme Pro SDHC,256GB, U3, C10, UHS-I,",
    price: 5799,
    rating: 4,
    totalRating: 50200,
    src1: sandisk256,
    src2: sandisk2562,
    src3: sandisk2563,
    src4: sandisk2564,
    src5: sandisk2565,
    inStock: 5,
    newestArrival: true,
    delivery: `${Math.trunc(Math.random() * 10) + 2} Days`,
    description: [
      "SanDisk Extreme Pro SDHC,256GB, U3, C10, UHS-I, 4K Video, 170MB/s R, 90MB/s W",
      "Make sure this fits by entering your model number.",
      "Shot speeds up to 60MB/s*, transfer speeds up to 150MB/s* and other factors. 1Mb=1, 000, 000 bytes. X = 150Kb/sec.",
      "Perfect for shooting 4K UHD video) and sequential burst mode photography",
      "Equipped with sweep panorama, intelligent auto and picture effect, Self-Timer: Off , 10sec., 2sec., portrait1, portrait2",
      "Capture uninterrupted video with UHS speed Class 3 (U3) and video Speed Class 30 (v30)(2)",
      "Built for and tested in harsh conditions): temperature-proof, waterproof, shock-proof, and X-ray-proof | (3)card only",
      "Lifetime limited manufacturer (30-year in Germany, Canada and regions not recognizing lifetime )",
    ],
  },
  {
    _id: "2022PEBCART2705PEBUI011",
    category: "accessories",
    brand: "godox",
    title: "Godox AD200Pro AD200 Pro with PERGEAR Diffuser",
    price: 35100,
    rating: 5,
    totalRating: 231,
    src1: godoxFlash,
    src2: godoxFlash2,
    src3: godoxFlash3,
    src4: godoxFlash4,
    src5: godoxFlash5,
    inStock: 6,
    newestArrival: false,
    delivery: `${Math.trunc(Math.random() * 10) + 2} Days`,
    description: [
      "Large capacity Lithium battery|: The Godot AD200 Pro offers 500 full power flashes with 0. 01 to 2. 1 sec. Recycling. Expanded manual 9 stop range of power adjusts from full to 1/256 in 1/10th stop increments",
      "4G Control with 330Ft Range|: Godot AD200Pro supports Nikon, Canon, Sony, Fujifilm, Olympus, Panasonic and Pentax TTL. With Master Slave Function, AD200Pro Can be Used in Combination with Godot TTL Camera Flashes, TTL Outdoor Flashes, etc.",
      "|manual/TTL/multi flash modes|: fully support flash exposure compensation, 1/8000S high speed Sync. Manual outputs the user's choice of power, modeling light, first/second curtain sync, etc. Multi controls the stroboscopic rate up to 90 times",
      "Record high definition videos with manual exposure control and multiple frame rates. Record 1080p videos at 30 frames per second or 720p videos at 60 frames per second.",
      "Lightweight portable easy to use |: easy to use buttons command a full range of functions like reliable Channel triggering, Group mode selectivity, HSS. The LCD panel clearly displays the status of all remote Strobes, so you always have control of group lighting setup",
    ],
  },
  {
    _id: "2022PEBCART2705PEBUI012",
    category: "accessories",
    brand: "peak design",
    title: "Peak Design Cuff Camera Wrist Strap (Black)",
    price: 4500,
    rating: 4,
    totalRating: 4213,
    src1: cameraStrap,
    src2: cameraStrap2,
    src3: cameraStrap3,
    src4: cameraStrap4,
    src5: cameraStrap5,
    inStock: 7,
    newestArrival: false,
    delivery: `${Math.trunc(Math.random() * 10) + 2} Days`,
    description: [
      "All products are from the USA",
      "All electronic products must be used with a step down/up converter for Indian voltage compatibility",
    ],
  },
  {
    _id: "2022PEBCART2705PEBUI013",
    category: "tripod",
    brand: "msg",
    title: "MGS Adjustable Aluminium Alloy Tripod Stand",
    price: 309,
    rating: 3.5,
    totalRating: 254,
    src1: msgTripod,
    src2: msgTripod2,
    src3: msgTripod3,
    src4: msgTripod4,
    src5: msgTripod5,
    inStock: 6,
    newestArrival: false,
    delivery: `${Math.trunc(Math.random() * 10) + 2} Days`,
    description: [
      "Video Camera Tripod Stand 3110",
      "Colour silver black",
      "MATERIAL Ammonium,Plastic MADE FORM high quality",
      "4-Section, Lever-Lock Legs:Uneven terrain? Need to frame the shot just so?",
      "Made of Aluminium alloy, THICKER leg tube than the ordinary tripod, sturdy adequate for long exposure work and keep the camera steady.",
      "And it has a handle that is easy to manipulate the tilt and tilt angles, which can achieve pan and tilt movements to achieve any angle required by the user.",
      "10 days Replacement warranty",
    ],
  },
  {
    _id: "2022PEBCART2705PEBUI014",
    category: "accessories",
    brand: "sandisk",
    title: "SanDisk 128GB Extreme Pro SDXC UHS-I Card - C10, U3, V30, 4K UHD",
    price: 2999,
    rating: 4.2,
    totalRating: 93250,
    src1: sandisk128,
    src2: sandisk1282,
    src3: sandisk1283,
    src4: sandisk1284,
    src5: sandisk1285,
    inStock: 3,
    newestArrival: false,
    delivery: `${Math.trunc(Math.random() * 10) + 2} Days`,
    description: [
      "Shot speeds up to 60MB/s*, transfer speeds up to 150MB/s* and other factors. 1Mb=1, 000, 000 bytes. X = 150Kb/sec.",
      "Perfect for shooting 4K UHD video) and sequential burst mode photography",
      "Capture uninterrupted video with UHS speed Class 3 (U3) and video Speed Class 30 (v30)(2)",
      "Built for and tested in harsh conditions): temperature-proof, waterproof, shock-proof, and X-ray-proof | (3)card only",
      "Lifetime limited manufacturer (30-year in Germany, Canada and regions not recognizing lifetime )",
      " Country of Origin: China",
    ],
  },
  {
    _id: "2022PEBCART2705PEBUI015",
    category: "tripod",
    brand: "humble",
    title: "HUMBLE® Octopus Tripod Foldable Flexible Tripod",
    price: 429,
    rating: 3.8,
    totalRating: 245,
    src1: humbleTripod,
    src2: humbleTripod2,
    src3: humbleTripod3,
    src4: humbleTripod4,
    src5: humbleTripod5,
    inStock: 4,
    newestArrival: true,
    delivery: `${Math.trunc(Math.random() * 10) + 2} Days`,
    description: [
      "USAGE Portable & Lightweight Flexible Tripod, The Tripod Can Be Fixed Anywhere You Want Such As The Lamppost, Tree Branch, Bicycle Handle And So On.",
      "PARAMETERS This Flexipod Is 12inches Large And Can Hold Weight Up To 2kg Making It Near Enough Possible To Hold Any DSLR. The Adapter Screw Allows You To Mount The Standard ¼ inch Cameras And Other Professional Tripod Heads.",
      "WIDE RANGE OF COMPATIBILITY This tripod can be used for smartphones, DSLR cameras & Action Cameras Including Gopro Hero 5 4 3, SJCAM & Other Action Cameras Compatible with All Smartphone,DSLR Cameras, Go Pro Hero 9/8/7, SJCAM, Eken, Noise & All Other Action Cameras.",
      "PACKAGE INCLUDES 1 x Tripod,1 x Phone mount clip",
    ],
  },
  {
    _id: "2022PEBCART2705PEBUI016",
    category: "accessories",
    brand: "polaroid",
    title: "Polaroid Now Camera Bag - Blue, Made from soft materials",
    price: 5449,
    rating: 4.7,
    totalRating: 20,
    src1: polaroidBag,
    src2: polaroidBag2,
    src3: polaroidBag3,
    src4: polaroidBag4,
    src5: polaroidBag5,
    inStock: 5,
    newestArrival: true,
    delivery: `${Math.trunc(Math.random() * 10) + 2} Days`,
    description: [
      "Works with Polaroid Now instant cameras and OneStep instant cameras",
      "Stylish camera bag is inspired by the Polaroid rainbow. Available in five colors to match your unique style.",
      "This clever accessory packs your Polaroid Now or OneStep instant camera perfectly for travels near and far.",
      "Made from soft materials with a foldover magnetic closure that helps keeps your camera safe and secure.",
      "Camera bag features a soft fleece lining and chunky adjustable strap with classic Polaroid branding.",
    ],
  },
  {
    _id: "2022PEBCART2705PEBUI017",
    category: "camera",
    brand: "gopro",
    title: "GoPro HERO10 Black - Front LCD and Touch Rear Screens,23MP.",
    price: 35400,
    rating: 4.5,
    totalRating: 103,
    src1: actionCamera,
    src2: actionCamera2,
    src3: actionCamera3,
    src4: actionCamera,
    src5: actionCamera2,
    inStock: 8,
    newestArrival: false,
    delivery: `${Math.trunc(Math.random() * 10) + 2} Days`,
    description: [
      "1 year international + 1 year local India warranty. For 1 Year extended warranty please visit gopro.luxurypersonified.co.in",
      "Revolutionary Processor: Faster. Smoother. Better. The powerful new GP2 engine changes the game—snappy performance, responsive touch controls and double the frame rate for amazingly smooth footage. Designed specifically for the demanding nature of the GoPro, the GP2 “system on a chip” is by far our fastest ever.",
      "High-Res Photos + Videos, High Frame Rate: Step up to the sharpest-shooting GoPro ever. Upgraded to incredible 23MP photos and 5.3K video resolution at 60fps, HERO10 offers double the frame rate for amazingly smooth motion. Plus, there’s 8x slo-mo at 2.7K and you can pause videos and grab 15.8MP still photos from 5.3K video that look amazing.",
      "Unbelievable Image Quality: Shoot with fine detail, realistic textures and stunning contrast, even in low light. Images look their best thanks to a new water-shedding hydrophobic lens cover that repels water while helping to eliminate lens flare and other artifacts.",
      "HyperSmooth 4.0: HyperSmooth has never been smoother, and it’s easy to dial in the best stabilization at all times. Plus, get even better low-light performance and horizon leveling with a higher tilt limit, so your footage looks perfectly straight when you want it.",
      "Compatible with Over 30 Accessories",
    ],
  },
  {
    _id: "2022PEBCART2705PEBUI018",
    category: "camera",
    brand: "sony",
    title: "Sony DSC W830 Cyber-Shot 20.1 MP Point & Shoot Camera",
    price: 10999,
    rating: 4,
    totalRating: 13,
    src1: sonySmallCamera,
    src2: sonySmallCamera2,
    src3: sonySmallCamera3,
    src4: sonySmallCamera4,
    src5: sonySmallCamera,
    inStock: 8,
    newestArrival: false,
    delivery: `${Math.trunc(Math.random() * 10) + 2} Days`,
    description: [
      "Super HAD CCD sensor with 20.1 effective megapixels : Included Components - 1 U (Including-Rechargeable battery, AC Adaptor, Multi USB Cable, Wrist Strap,User Manual)",
      "720p MP4 movie mode the camera shoots 1280 x 720 high definition movies at 30 fps, Focal length f=4.5-36mm. Exposure Compensation: +/- 2.0 EV, 1/3 EV step",
      "8x optical zoom Carl Zeiss Vario Tessar lens.Refer user manual ; Screen type: 6.7cm(2.7-type)(4:3) / 230,400 dots / ClearPhoto / TFT LCD ; Brightness Control: 5 (Bright) / 4 / 3 / 2 / 1 (Dark)",
      "Equipped with sweep panorama, intelligent auto and picture effect, Self-Timer: Off , 10sec., 2sec., portrait1, portrait2",
      "Power Consumption (Camera Mode): Approx. 1.2W; Power Source: DC3.6V (supplied battery)/DC5.0V (supplied AC adaptor)",
    ],
  },
  {
    _id: "2022PEBCART2705PEBUI019",
    category: "camera",
    brand: "canon",
    title: "Canon EOS 6D DSLR Camera (Kit 24 - 105)  (Black)",
    price: 135100,
    rating: 3.5,
    totalRating: 40,
    src1: canon6dCamera,
    src2: canon6dCamera2,
    src3: canon6dCamera3,
    src4: canon6dCamera4,
    src5: canon6dCamera5,
    inStock: 8,
    newestArrival: false,
    delivery: `${Math.trunc(Math.random() * 10) + 2} Days`,
    description: [
      "This camera comes with a high resolution 20.2-megapixel CMOS sensor capture sharp images with quality and detail. It has a new photodiode construction, gapless microlens array and on-chip noise reduction circuitry on the sensor to keep the image data clean and clear, even at high ISO settings.",
      "The Canon EOS 6D lets you take pictures in any environment, bright or low-lit, as it comes with a flexible ISO speed setting between 50 and 102400. It lets you adjust the camera's sensitivity to light, giving you great photos in both day and night.",
      "As the Canon EOS 6D supports IEEE 802.11 b/g/n networks, you share data between your camera and Wi-Fi enabled devices or on social networking sites. With built-in GPS tracking, you can tag the place where you took the photo with accurate GPS coordinates. You can also use your smartphone to remotely control the camera through Wi-Fi.",
      "Record high definition videos with manual exposure control and multiple frame rates. Record 1080p videos at 30 frames per second or 720p videos at 60 frames per second.",
      "High Dynamic Range lets you take high-quality videos with ease, allowing you to expand your creative mindset using various features such as Scene Intelligent Auto and Special Scene Modes to get the perfect shot.",
      "This camera is compatible with SD/SDHC/SDXC memory cards, allowing you to store more photos and videos.",
    ],
  },
  {
    _id: "2022PEBCART2705PEBUI020",
    category: "lens",
    brand: "canon",
    title: "Canon EF 85mm f1.2L II USM Lens for Canon DSLR Cameras",
    price: 85100,
    rating: 3.2,
    totalRating: 13,
    src1: lens85mm,
    src2: lens85mm,
    src3: lens85mm,
    src4: lens85mm,
    src5: lens85mm,
    inStock: 0,
    newestArrival: false,
    delivery: `${Math.trunc(Math.random() * 10) + 2} Days`,
    description: [
      "Beautiful bokeh with DS* coating",
      "High image quality and bright f/1.2 aperture mid-telephoto zoom RF L lens.",
      "Blue spectrum refractive optics (br) Reduces chromatic aberration.",
      "Minimum focusing distance of 2.79 ft./0.85M.",
      "Control ring for direct setting changes.",
      "Lens Type: Macro",
    ],
  },
  {
    _id: "2022PEBCART2705PEBUI021",
    category: "lens",
    brand: "papa",
    title: "Apexel 4K HD Mobile Phone 5-in-1 Camera Lens Kit",
    price: 540,
    rating: 2.2,
    totalRating: 25,
    src1: lensForMobile,
    src2: lensForMobile2,
    src3: lensForMobile3,
    src4: lensForMobile4,
    src5: lensForMobile5,
    inStock: 0,
    newestArrival: false,
    delivery: `${Math.trunc(Math.random() * 10) + 2} Days`,
    description: [
      "The 4K HD Mobile Phone 5-in-1 Camera Lens Kit from Apexel is a bundle that contains a 2x Zoom Telephone Lens, a 0.6x / 110° Wide-Angle Lens, a 170° Super Wide-Angle Lens, a 195° Fisheye Lens, and a 10x Macro Lens. These lenses are compatible with iPhone XS Max, XR, X, 8, 7, and 6 Plus, as well as Samsung phones.",
      "This lens provides a magnification of 10x for detailed close-ups. The Telephoto Lens provides a minimum magnification of 2x to deliver photos from a distance that are of higher quality. If you'd like to create subjects with a blurred background, please keep the lens 12 to 40 from your subject. To fully utilize the 2x magnification, keep the lens at least 60 from the subject.",
      "Shoot a wide range of scenery with a magnification of 0.6x and a 110° angle of view. This lens is suitable for group selfies, travel, landscapes, and more.",
    ],
  },
  {
    _id: "2022PEBCART2705PEBUI022",
    category: "camera",
    brand: "polaroid",
    title: "Polaroid Originals Now I-Type Instant Camera - (9030)",
    price: 12999,
    rating: 2.1,
    totalRating: 14,
    src1: polaroidCamera,
    src2: polaroidCamera2,
    src3: polaroidCamera3,
    src4: polaroidCamera4,
    src5: polaroidCamera5,
    inStock: 7,
    newestArrival: false,
    delivery: `${Math.trunc(Math.random() * 10) + 2} Days`,
    description: [
      "NOW: Polaroid's new point-and-shoot analog instant camera has all you need to catch every life moment in an original Polaroid photograph.",
      "NEW & IMPROVED: Now with autofocus, it’s simple to capture moments as you see them, so you can relive them forever in sharp, vivid color.",
      " DOUBLE EXPOSURE: Frame two moments in one with double exposure, or get yourself in the picture with self-timer and an accurate flash to make everyone look like they should.",
      "COLORFUL FUN: Now is available in the 7 colors of the iconic Polaroid spectrum.",
      " POINT. SHOOT. KEEP FOREVER: Now is compatible with both i-Type and 600 film.",
    ],
  },
  {
    _id: "2022PEBCART2705PEBUI023",
    category: "camera",
    brand: "sony",
    title: "Sony Alpha ILCE-7RM3A Full-Frame 42.4MP Mirrorless Camera Body",
    price: 202200,
    rating: 1.6,
    totalRating: 34,
    src1: sonyAlphaCamera,
    src2: sonyAlphaCamera2,
    src3: sonyAlphaCamera3,
    src4: sonyAlphaCamera4,
    src5: sonyAlphaCamera5,
    inStock: 7,
    newestArrival: false,
    delivery: `${Math.trunc(Math.random() * 10) + 2} Days`,
    description: [
      "35mm full-frame “Exmor R” CMOS sensor with approx 42.4 effective megapixels",
      "Enhanced BIONZ X image processing engine with front end LSI",
      "ISO sensitivity: 100 – 32000 (expandable 50 – 102400)",
      "14bit RAW format (ARW)",
      "Image sensor-shift mechanism (5-axis compensation) of a 5.5 stop compensation performance",
      "No optical low-pass filter",
      "Pixel Shift Multi Shooting",
      "Continuous shooting at up to 10 fps (with AF/AE tracking)",
      "Silent and vibration-free shooting (Electronical shutter)",
      "2 Year standard warranty + 1 year extended warranty on registration on alpha community",
      "399 points Hybrid Phase Detection AF + 425 Contrast AF",
      "Country of Origin: China",
    ],
  },
  {
    _id: "2022PEBCART2705PEBUI024",
    category: "camera",
    brand: "canon",
    title: "Canon EOS 1500D 24.1 Digital SLR Camera EF S18-55 is II Lens",
    price: 18999,
    rating: 1.8,
    totalRating: 30,
    src1: cameraCanon,
    src2: cameraCanon2,
    src3: cameraCanon3,
    src4: cameraCanon4,
    src5: cameraCanon5,
    inStock: 7,
    newestArrival: true,
    delivery: `${Math.trunc(Math.random() * 10) + 2} Days`,
    description: [
      "Sensor: APS-C CMOS Sensor with 24.1 MP (high resolution for large prints and image cropping). Transmission frequency (central frequency):Frequency: 2 412 to 2 462MHz. Standard diopter :-2.5 - +0.5m-1 (dpt)",
      "ISO: 100-6400 sensitivity range (critical for obtaining grain-free pictures, especially in low light)",
      "Image Processor: DIGIC 4+ with 9 autofocus points (important for speed and accuracy of autofocus and burst photography)",
      "Video Resolution: Full HD video with fully manual control and selectable frame rates (great for precision and high-quality video work)",
      " Connectivity: WiFi, NFC and Bluetooth built-in (useful for remotely controlling your camera and transferring pictures wirelessly as you shoot)",
      "Lens Mount: EF-S mount compatible with all EF and EF-S lenses (crop-sensor mount versatile and compact, especially when used with EF-S lenses)",
      "Country of Origin: Taiwan",
    ],
  },
  {
    _id: "2022PEBCART2705PEBUI025",
    category: "camera",
    brand: "gopro",
    title: "GoPro HERO10 Black - Front LCD and Touch Rear Screens,23MP.",
    price: 35400,
    rating: 1.4,
    totalRating: 103,
    src1: actionCamera,
    src2: actionCamera2,
    src3: actionCamera3,
    src4: actionCamera,
    src5: actionCamera2,
    inStock: 20,
    newestArrival: false,
    delivery: `${Math.trunc(Math.random() * 10) + 2} Days`,
    description: [
      "1 year international + 1 year local India warranty. For 1 Year extended warranty please visit gopro.luxurypersonified.co.in",
      "Revolutionary Processor: Faster. Smoother. Better. The powerful new GP2 engine changes the game—snappy performance, responsive touch controls and double the frame rate for amazingly smooth footage. Designed specifically for the demanding nature of the GoPro, the GP2 “system on a chip” is by far our fastest ever.",
      "High-Res Photos + Videos, High Frame Rate: Step up to the sharpest-shooting GoPro ever. Upgraded to incredible 23MP photos and 5.3K video resolution at 60fps, HERO10 offers double the frame rate for amazingly smooth motion. Plus, there’s 8x slo-mo at 2.7K and you can pause videos and grab 15.8MP still photos from 5.3K video that look amazing.",
      "Unbelievable Image Quality: Shoot with fine detail, realistic textures and stunning contrast, even in low light. Images look their best thanks to a new water-shedding hydrophobic lens cover that repels water while helping to eliminate lens flare and other artifacts.",
      "HyperSmooth 4.0: HyperSmooth has never been smoother, and it’s easy to dial in the best stabilization at all times. Plus, get even better low-light performance and horizon leveling with a higher tilt limit, so your footage looks perfectly straight when you want it.",
      "Compatible with Over 30 Accessories",
    ],
  },
  {
    _id: "2022PEBCART2705PEBUI026",
    category: "camera",
    brand: "sony",
    title: "Sony DSC W830 Cyber-Shot 20.1 MP Point & Shoot Camera",
    price: 10999,
    rating: 3.7,
    totalRating: 13,
    src1: sonySmallCamera,
    src2: sonySmallCamera2,
    src3: sonySmallCamera3,
    src4: sonySmallCamera4,
    src5: sonySmallCamera,
    inStock: 20,
    newestArrival: false,
    delivery: `${Math.trunc(Math.random() * 10) + 2} Days`,
    description: [
      "Super HAD CCD sensor with 20.1 effective megapixels : Included Components - 1 U (Including-Rechargeable battery, AC Adaptor, Multi USB Cable, Wrist Strap,User Manual)",
      "720p MP4 movie mode the camera shoots 1280 x 720 high definition movies at 30 fps, Focal length f=4.5-36mm. Exposure Compensation: +/- 2.0 EV, 1/3 EV step",
      "8x optical zoom Carl Zeiss Vario Tessar lens.Refer user manual ; Screen type: 6.7cm(2.7-type)(4:3) / 230,400 dots / ClearPhoto / TFT LCD ; Brightness Control: 5 (Bright) / 4 / 3 / 2 / 1 (Dark)",
      "Equipped with sweep panorama, intelligent auto and picture effect, Self-Timer: Off , 10sec., 2sec., portrait1, portrait2",
      "Power Consumption (Camera Mode): Approx. 1.2W; Power Source: DC3.6V (supplied battery)/DC5.0V (supplied AC adaptor)",
    ],
  },
  {
    _id: "2022PEBCART2705PEBUI027",
    category: "camera",
    brand: "canon",
    title: "Canon EOS 6D DSLR Camera (Kit 24 - 105)  (Black)",
    price: 135100,
    rating: 4.5,
    totalRating: 40,
    src1: canon6dCamera,
    src2: canon6dCamera2,
    src3: canon6dCamera3,
    src4: canon6dCamera4,
    src5: canon6dCamera5,
    inStock: 0,
    newestArrival: false,
    delivery: `${Math.trunc(Math.random() * 10) + 2} Days`,
    description: [
      "This camera comes with a high resolution 20.2-megapixel CMOS sensor capture sharp images with quality and detail. It has a new photodiode construction, gapless microlens array and on-chip noise reduction circuitry on the sensor to keep the image data clean and clear, even at high ISO settings.",
      "The Canon EOS 6D lets you take pictures in any environment, bright or low-lit, as it comes with a flexible ISO speed setting between 50 and 102400. It lets you adjust the camera's sensitivity to light, giving you great photos in both day and night.",
      "As the Canon EOS 6D supports IEEE 802.11 b/g/n networks, you share data between your camera and Wi-Fi enabled devices or on social networking sites. With built-in GPS tracking, you can tag the place where you took the photo with accurate GPS coordinates. You can also use your smartphone to remotely control the camera through Wi-Fi.",
      "Record high definition videos with manual exposure control and multiple frame rates. Record 1080p videos at 30 frames per second or 720p videos at 60 frames per second.",
      "High Dynamic Range lets you take high-quality videos with ease, allowing you to expand your creative mindset using various features such as Scene Intelligent Auto and Special Scene Modes to get the perfect shot.",
      "This camera is compatible with SD/SDHC/SDXC memory cards, allowing you to store more photos and videos.",
    ],
  },
  {
    _id: "2022PEBCART2705PEBUI028",
    category: "lens",
    brand: "canon",
    title: "Canon EF 85mm f1.2L II USM Lens for Canon DSLR Cameras",
    price: 85100,
    rating: 4,
    totalRating: 13,
    src1: lens85mm,
    src2: lens85mm,
    src3: lens85mm,
    src4: lens85mm,
    src5: lens85mm,
    inStock: 0,
    newestArrival: false,
    delivery: `${Math.trunc(Math.random() * 10) + 2} Days`,
    description: [
      "Beautiful bokeh with DS* coating",
      "High image quality and bright f/1.2 aperture mid-telephoto zoom RF L lens.",
      "Blue spectrum refractive optics (br) Reduces chromatic aberration.",
      "Minimum focusing distance of 2.79 ft./0.85M.",
      "Control ring for direct setting changes.",
      "Lens Type: Macro",
    ],
  },
  {
    _id: "2022PEBCART2705PEBUI029",
    category: "lens",
    brand: "papa",
    title: "Apexel 4K HD Mobile Phone 5-in-1 Camera Lens Kit",
    price: 299,
    rating: 2.6,
    totalRating: 25,
    src1: lensForMobile,
    src2: lensForMobile2,
    src3: lensForMobile3,
    src4: lensForMobile4,
    src5: lensForMobile5,
    inStock: 10,
    newestArrival: false,
    delivery: `${Math.trunc(Math.random() * 10) + 2} Days`,
    description: [
      "The 4K HD Mobile Phone 5-in-1 Camera Lens Kit from Apexel is a bundle that contains a 2x Zoom Telephone Lens, a 0.6x / 110° Wide-Angle Lens, a 170° Super Wide-Angle Lens, a 195° Fisheye Lens, and a 10x Macro Lens. These lenses are compatible with iPhone XS Max, XR, X, 8, 7, and 6 Plus, as well as Samsung phones.",
      "This lens provides a magnification of 10x for detailed close-ups. The Telephoto Lens provides a minimum magnification of 2x to deliver photos from a distance that are of higher quality. If you'd like to create subjects with a blurred background, please keep the lens 12 to 40 from your subject. To fully utilize the 2x magnification, keep the lens at least 60 from the subject.",
      "Shoot a wide range of scenery with a magnification of 0.6x and a 110° angle of view. This lens is suitable for group selfies, travel, landscapes, and more.",
    ],
  },

  {
    _id: "2022PEBCART2705PEBUI030",
    category: "camera",
    brand: "canon",
    title: "Canon EOS 1500D 24.1 Digital SLR Camera EF S18-55 is II Lens",
    price: 18999,
    rating: 1.2,
    totalRating: 30,
    src1: cameraCanon,
    src2: cameraCanon2,
    src3: cameraCanon3,
    src4: cameraCanon4,
    src5: cameraCanon5,
    inStock: 7,
    newestArrival: false,
    delivery: `${Math.trunc(Math.random() * 10) + 2} Days`,
    description: [
      "Sensor: APS-C CMOS Sensor with 24.1 MP (high resolution for large prints and image cropping). Transmission frequency (central frequency):Frequency: 2 412 to 2 462MHz. Standard diopter :-2.5 - +0.5m-1 (dpt)",
      "ISO: 100-6400 sensitivity range (critical for obtaining grain-free pictures, especially in low light)",
      "Image Processor: DIGIC 4+ with 9 autofocus points (important for speed and accuracy of autofocus and burst photography)",
      "Video Resolution: Full HD video with fully manual control and selectable frame rates (great for precision and high-quality video work)",
      " Connectivity: WiFi, NFC and Bluetooth built-in (useful for remotely controlling your camera and transferring pictures wirelessly as you shoot)",
      "Lens Mount: EF-S mount compatible with all EF and EF-S lenses (crop-sensor mount versatile and compact, especially when used with EF-S lenses)",
      "Country of Origin: Taiwan",
    ],
  },
  {
    _id: "2022PEBCART2705PEBUI031",
    category: "camera",
    brand: "gopro",
    title: "GoPro HERO10 Black - Front LCD and Touch Rear Screens,23MP.",
    price: 35400,
    rating: 4.5,
    totalRating: 103,
    src1: actionCamera,
    src2: actionCamera2,
    src3: actionCamera3,
    src4: actionCamera,
    src5: actionCamera2,
    inStock: 5,
    newestArrival: true,
    delivery: `${Math.trunc(Math.random() * 10) + 2} Days`,
    description: [
      "1 year international + 1 year local India warranty. For 1 Year extended warranty please visit gopro.luxurypersonified.co.in",
      "Revolutionary Processor: Faster. Smoother. Better. The powerful new GP2 engine changes the game—snappy performance, responsive touch controls and double the frame rate for amazingly smooth footage. Designed specifically for the demanding nature of the GoPro, the GP2 “system on a chip” is by far our fastest ever.",
      "High-Res Photos + Videos, High Frame Rate: Step up to the sharpest-shooting GoPro ever. Upgraded to incredible 23MP photos and 5.3K video resolution at 60fps, HERO10 offers double the frame rate for amazingly smooth motion. Plus, there’s 8x slo-mo at 2.7K and you can pause videos and grab 15.8MP still photos from 5.3K video that look amazing.",
      "Unbelievable Image Quality: Shoot with fine detail, realistic textures and stunning contrast, even in low light. Images look their best thanks to a new water-shedding hydrophobic lens cover that repels water while helping to eliminate lens flare and other artifacts.",
      "HyperSmooth 4.0: HyperSmooth has never been smoother, and it’s easy to dial in the best stabilization at all times. Plus, get even better low-light performance and horizon leveling with a higher tilt limit, so your footage looks perfectly straight when you want it.",
      "Compatible with Over 30 Accessories",
    ],
  },
];

export { products };
